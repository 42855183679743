import React from 'react';
import T1 from '../images/team/1.jpg'
import T2 from '../images/team/2.jpg'
import T3 from '../images/team/3.jpg'
import T4 from '../images/team/4.jpg'
import T5 from '../images/team/5.jpg'
import T6 from '../images/team/6.jpg'
import T7 from '../images/team/7.jpg'


const Team = () => (
  <section id="team-section" className="page">
    <div className="page-header-wrapper">
      <div className="container">
        <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
          <h2>Our Team</h2>
          <div className="devider"></div>
          <p className="subtitle">Meat our experts</p>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="team-items">
          <div className="col-md-2">
            <div className="team-container wow bounceIn" data-wow-delay="0.2s">
              <div className="team-item">
                <div className="team-triangle">
                  <div className="content">
                    <img src={T1} alt="title" />
                    <div className="team-hover text-center">
                      <i className="fa fa-male"></i>
                      <p>John Doe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="team-container wow bounceIn" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="team-triangle">
                  <div className="content">
                   <img src={T2} alt="title" />
                    <div className="team-hover text-center">
                      <i className="fa fa-female"></i>
                      <p>Jane Doe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="team-container wow bounceIn" data-wow-delay="0.4s">
              <div className="team-item">
                <div className="team-triangle">
                  <div className="content">
                   <img src={T3} alt="title" />
                    <div className="team-hover text-center">
                      <i className="fa fa-male"></i>
                      <p>John Doe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="team-container wow bounceIn" data-wow-delay="0.5s">
              <div className="team-item">
                <div className="team-triangle">
                  <div className="content">
                   <img src={T4} alt="title" />
                    <div className="team-hover text-center">
                      <i className="fa fa-male"></i>
                      <p>John Doe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="team-container wow bounceIn" data-wow-delay="0.6s">
              <div className="team-item">
                <div className="team-triangle">
                  <div className="content">
                   <img src={T5} alt="title" />
                    <div className="team-hover text-center">
                      <i className="fa fa-male"></i>
                      <p>John Doe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="team-container wow bounceIn" data-wow-delay="0.7s">
              <div className="team-item">
                <div className="team-triangle">
                  <div className="content">
                   <img src={T6}  alt="title" />
                    <div className="team-hover text-center">
                      <i className="fa fa-male"></i>
                      <p>John Doe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="team-container wow bounceIn" data-wow-delay="0.8s">
              <div className="team-item">
                <div className="team-triangle">
                  <div className="content">
                   <img src={T7} alt="title" />
                    <div className="team-hover text-center">
                      <i className="fa fa-female"></i>
                      <p>Jane Doe</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  </section>
);

export default Team;
