import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const IntroSlideshow = () => {
	//Owl Carousel Settings
    const options = {
		items: 1, // This sets the number of items to be shown at once
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
	};
	return (
	<section id="text-carousel-intro-section" className="parallax" data-stellar-background-ratio="0.5">
		<div className="container">
			<div className="caption text-center text-white" data-stellar-ratio="0.7">
			<OwlCarousel id='owl-intro-text' className="owl-carousel" {...options}>
					<div className="item">
						<h1>We have Awesome things</h1>
						<p>Let's make the web beautiful together</p>
						<div className="extra-space-l"></div>
						<a className="btn btn-blank" href="https://creativemarket.com/Themetorium" target="_blank" role="button">View More!</a>
					</div>
					<div className="item">
						<h1>Join with us</h1>
						<p>To the greatest Journey</p>
						<div className="extra-space-l"></div>
						<a className="btn btn-blank" href="https://creativemarket.com/Themetorium" target="_blank" role="button">View More!</a>
					</div>
					<div className="item">
						<h1>I'm Unika</h1>
						<p>One Page Responsive Theme</p>
						<div className="extra-space-l"></div>
						<a className="btn btn-blank" href="https://creativemarket.com/Themetorium" target="_blank" role="button">View More!</a>
					</div>

			</OwlCarousel>
			</div>
		</div>

	</section>
)};

export default IntroSlideshow;
