import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './include/bootstrap/css/bootstrap.min.css'
import './include/animations/css/animate.min.css'
import './include/font-awesome/css/font-awesome.min.css'
import './include/owl-carousel/css/owl.carousel.css'
import './include/owl-carousel/css/owl.theme.css'
import './css/style.css'
import './css/mobile.css'
import './css/skin/cool-gray.css'
import Helmet  from 'react-helmet';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Helmet>
        <script src="/include/jquery/jquery-1.11.1.min.js"></script>
        <script src="/include/stellar/js/jquery.stellar.min.js"></script>
        <script src="/include/jquery.easing.min.js"></script>
        <script src="/include/jquery.counterup.min.js"></script>
        <script src="/include/smoothscroll.js"></script>
        <script src="/include/bootstrap/js/bootstrap.min.js"></script>
        <script src="/include/animations/js/wow.min.js"></script>
        <script src="/include/waypoints.min.js"></script>
        <script src="/include/isotope.pkgd.min.js"type="text/babel"></script>
        <script src="/include/classie.js"></script>
      </Helmet>
  </React.StrictMode>
);
