import React from 'react';

const Social = () => (
  <section id="social-section">

    <div className="page-header-wrapper">
      <div className="container">
        <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
          <h2>Join Us</h2>
          <div className="devider"></div>
          <p className="subtitle">Follow us on social networks</p>
        </div>
      </div>
    </div>

    <div className="container">
      <ul className="social-list">
        <li> <a className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.3s"><span className="rotate-box-icon"><i className="fa fa-facebook"></i></span></a></li>
        <li> <a className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.4s"><span className="rotate-box-icon"><i className="fa fa-twitter"></i></span></a></li>
        <li> <a className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.5s"><span className="rotate-box-icon"><i className="fa fa-google-plus"></i></span></a></li>
        <li> <a className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.6s"><span className="rotate-box-icon"><i className="fa fa-pinterest-p"></i></span></a></li>
        <li> <a className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.7s"><span className="rotate-box-icon"><i className="fa fa-tumblr"></i></span></a></li>
        <li> <a className="rotate-box-1 square-icon text-center wow zoomIn" data-wow-delay="0.8s"><span className="rotate-box-icon"><i className="fa fa-dribbble"></i></span></a></li>
      </ul>
    </div>

  </section>
);

export default Social;
