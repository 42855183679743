import React from 'react';

const Navbar = () => {
  const scrollToTarget = (target) => {
    const targetElement = document.getElementById(target);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav id="main-navbar" className="navbar navbar-default navbar-fixed-top" role="navigation">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="index.html">nyzk</a>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li><a onClick={() => scrollToTarget('text-carousel-intro-section')}>Home</a></li>
            <li><a onClick={() => scrollToTarget('about-section')}>About</a></li>
            <li><a onClick={() => scrollToTarget('services-section')}>Services</a></li>
            <li><a onClick={() => scrollToTarget('portfolio-section')}>Works</a></li>
            <li><a onClick={() => scrollToTarget('team-section')}>Team</a></li>
            <li><a onClick={() => scrollToTarget('contact-section')}>Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;