import React from 'react';

const About = () => (
  <section id="about-section" className="page bg-style1">
    <div className="page-header-wrapper">
      <div className="container">
        <div className="page-header text-center wow fadeInUp" data-wow-delay="0.3s">
          <h2>About</h2>
          <div className="devider"></div>
          <p className="subtitle">A glimpse into who we are</p>
        </div>
      </div>
    </div>

    <div className="rotate-box-1-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <a className="rotate-box-1 square-icon wow zoomIn" data-wow-delay="0">
              <span className="rotate-box-icon"><i className="fa fa-users"></i></span>
              <div className="rotate-box-info">
                <h4>Who We Are</h4>
                <p>We are a passionate team of software engineers dedicated to innovation and excellence.</p>
              </div>
            </a>
          </div>

          <div className="col-md-3 col-sm-6">
            <a className="rotate-box-1 square-icon wow zoomIn" data-wow-delay="0.2s">
              <span className="rotate-box-icon"><i className="fa fa-diamond"></i></span>
              <div className="rotate-box-info">
                <h4>Our Expertise</h4>
                <p>With experience across industries, we deliver solutions that align with your goals.</p>
              </div>
            </a>
          </div>

          <div className="col-md-3 col-sm-6">
            <a className="rotate-box-1 square-icon wow zoomIn" data-wow-delay="0.4s">
              <span className="rotate-box-icon"><i className="fa fa-heart"></i></span>
              <div className="rotate-box-info">
                <h4>Why Choose Us</h4>
                <p>Partner with us for innovative solutions that exceed expectations and drive success.</p>
              </div>
            </a>
          </div>

          <div className="col-md-3 col-sm-6">
            <a className="rotate-box-1 square-icon wow zoomIn" data-wow-delay="0.6s">
              <span className="rotate-box-icon"><i className="fa fa-clock-o"></i></span>
              <div className="rotate-box-info">
                <h4>Our Commitment</h4>
                <p>We prioritize transparency and collaboration to build lasting relationships with our clients.</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;