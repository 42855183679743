import React from 'react';
import Navbar from '../Navbar/Navbar'

const Header = () => (
  <header id="header" className="header-main">
    <Navbar/>
  </header>
);

export default Header;
