import './App.css';
import Header from './Header/Header'
import IntroSlideshow from './IntroSlideshow/IntroSlideshow'
import About from './About/About'
import Services from './Services/Services'
import Reviews from './Reviews/Reviews'
import Works from './Works/Works'
import Team from './Team/Team'
import Contact from './Contact/Contact'
import Social from './Social/Social'
import Footer from './Footer/Footer'

import React  from 'react';

function App() {
  return (
    <div className="body" data-spy="scroll" data-target="#main-navbar">
      <Header/>
      <IntroSlideshow/>
      <About/>
      <Services/>
      <Reviews/>
      <Works/>
      <Team/>
      <Social/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
