import React, { useEffect } from 'react';
import P1 from '../images/portfolio/p1.jpg'
import P2 from '../images/portfolio/p2.jpg'
import P3 from '../images/portfolio/p3.jpg'
import P4 from '../images/portfolio/p4.jpg'
import P5 from '../images/portfolio/p5.jpg'
import P6 from '../images/portfolio/p6.jpg'
import P7 from '../images/portfolio/p7.jpg'
import P8 from '../images/portfolio/p8.jpg'
import P9 from '../images/portfolio/p9.jpg'

const Works = () => {
  useEffect(() => {
    // Add click event listener to filter links
    const filterLinks = document.querySelectorAll('#filters a');
    filterLinks.forEach(link => {
      link.addEventListener('click', handleFilterClick);
    });

    // Cleanup event listeners on unmount
    return () => {
      filterLinks.forEach(link => {
        link.removeEventListener('click', handleFilterClick);
      });
    };
  }, []);

  const handleFilterClick = (event) => {
    const filterValue = event.target.getAttribute('data-filter');
    const portfolioItems = document.querySelectorAll('.portfolio_content .col-xs-12');

    portfolioItems.forEach(item => {
      if (filterValue === '*' || item.classList.contains(filterValue.substring(1))) {
        item.style.display = 'block';
      } else {
        item.style.display = 'none';
      }
    });

    // Manage active class
    const filterLinks = document.querySelectorAll('#filters a');
    filterLinks.forEach(link => link.classList.remove('active'));
    event.target.classList.add('active');
  };
  return (
    <section id="portfolio-section" className="page bg-style1">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="portfolio">
              <div className="page-header-wrapper">
                <div className="container">
                  <div className="page-header text-center wow fadeInDown" data-wow-delay="0.4s">
                    <h2>Our Works</h2>
                    <div className="devider"></div>
                    <p className="subtitle">What we are proud of</p>
                  </div>
                </div>
              </div>
              <div className="portfoloi_content_area">
                <div className="portfolio_menu" id="filters">
                  <ul>
                    <li><a href="#portfolio_menu" data-filter="*" className="active">all</a></li>
                    <li><a href="#portfolio_menu" data-filter=".websites">websites</a></li>
                    <li><a href="#portfolio_menu" data-filter=".webDesign">web design</a></li>
                    <li><a href="#portfolio_menu" data-filter=".appsDevelopment">apps development</a></li>
                    <li><a href="#portfolio_menu" data-filter=".GraphicDesign">graphic design</a></li>
                    <li><a href="#portfolio_menu" data-filter=".responsive">responsive</a></li>
                  </ul>
                </div>
                <div className="portfolio_content">
                  <div className="row" id="portfolio">
                    <div className="col-xs-12 col-sm-4 appsDevelopment">
                      <div className="portfolio_single_content">
                        <img src={P1} alt="title" />
                        <div>
                          <a >Skull Awesome</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 GraphicDesign">
                      <div className="portfolio_single_content">
                        <img src={P2} alt="title" />
                        <div>
                          <a >Photo Frame</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 responsive">
                      <div className="portfolio_single_content">
                        <img src={P3} alt="title" />
                        <div>
                          <a >Hand Shots</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 webDesign websites">
                      <div className="portfolio_single_content">
                        <img src={P4} alt="title" />
                        <div>
                          <a >Night Abstract</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 appsDevelopment websites">
                      <div className="portfolio_single_content">
                        <img src={P5} alt="title" />
                        <div>
                          <a >Joy of Independence</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 GraphicDesign">
                      <div className="portfolio_single_content">
                        <img src={P6} alt="title" />
                        <div>
                          <a >Night Crawlers</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 responsive">
                      <div className="portfolio_single_content">
                        <img src={P7} alt="title" />
                        <div>
                          <a >Last Motel</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 GraphicDesign">
                      <div className="portfolio_single_content">
                        <img src={P8} alt="title" />
                        <div>
                          <a >Dirk Road</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 websites">
                      <div className="portfolio_single_content">
                        <img src={P9} alt="title" />
                        <div>
                          <a >Old is Gold</a>
                          <span>Subtitle</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Works;
